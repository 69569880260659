export default {
  API_ROOT: "api.ladyluck.greentubepro.com",
  TITLE: "Francine\u2019s Fortune - Lucky Jackpots",
  FACEBOOK_PIXEL_BASE: undefined,
  XTREMEPUSH_ID: "",
  APPLICATION_TARGET: "ladyluck",
  APPLICATION_NAME: "Francine\u2019s Fortune - Lucky Jackpots",
  GOOGLE_CLIENT_ID: "http://577032622575-1ph1l0kh3bdclncf85m8gsnlj81c6pc1.apps.googleusercontent.com",
  GOOGLE_AD_URL: "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js",
  ENVIRONMENT: "prod",
  PRELOAD_ASSETS: "true",
  MOBILE_WEB_ONE_LINK: "https://francinesfortunesocial.onelink.me/NL9Q",
  HELP_LINK: "francinesfortunehelp.zendesk.com",
  SITE_KEY: "",
  ACCOUNT_EMAIL: "false",
  ACCOUNT_CHANGE_PASSWORD: "false",
  PLAY_AS_GUEST: "false",
  APPLE_STORE_URL: undefined,
  ANDROID_STORE_URL: "https://play.google.com/store/apps/details?id=com.greentubepro.ladyluck",
  DSDK_APP_TOKEN: "92b40409-f7cf-4bb7-8862-fa6a2f2ec85d",
  DSDK_HASH_KEY: "9AEB9C05A33EAEDA4612A",
  DSDK_SV_URL: "https://a.bluebat.dive.games/ladyluck",
  DSDK_API_URL: "https://api.bluebat.dive.games",
  DSDK_DOMAIN: "social.francinesfortune.com",
  EXPERIAN_ENVIRONMENT_KEY: undefined,
  SSO_DEV: undefined,
  NODE_ENV: "production",
  CDN_ROOT: "social.francinesfortune.com",
  APP_ID: undefined,
  VERSION_MAJOR: "2",
  VERSION_MINOR: "12",
  VERSION_PATCH: "0",
  VERSION_BUILD: "1",
  PUBLIC_URL: undefined,
  ZENDESK_URL: "https://francinesfortunehelp.zendesk.com/hc/en-us/requests/new",
  LINKING_CARD_POPUP_ON_HIT_REWARD_CENTER: "false",
  SHOW_PROPERTY_SELECTOR: "true",
  DYNAMIC_HIGHLIMIT_POPUP: "true",
  DYNAMIC_PROPERTY: "true",
  KEYCHAIN_SERVICE_KEY: undefined,
  SHARED_PREFERENCES_KEY: undefined,
  DIVE_ERROR_HANDLING: "yes" ? "yes" : 'no',
  ENABLE_API_TRACKING: "true",
  GOOGLE_TAG_MANAGER_CONTAINER_ID: "GT-PHG6CNZW",
  APPSFLYER_PATH_PREFIX: undefined,
  LANGUAGE: "en",
  PLAY_AS_GUEST_TYPE: "BUTTON",
  ONE_TRUST_DATA_DOMAIN_SCRIPT: "01919ec0-0942-730c-867b-2b5a477d95fb",
  FACEBOOK_LOGIN: "false",
  COMING_SOON: "false",
  MOBILE_WEB_SUPPORT: "ios"
};